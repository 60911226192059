import $ from "jquery";
import '../../assets/js/select2.min'

export default {
    name: "Register",
    mounted () {
        $( document ).ready(function() {
            $('.select2').select2({minimumResultsForSearch: -1});
            if($('span.select2-selection__arrow').length > 0) {
                let span = $("span.select2-selection__arrow")[0];
                span.parentNode.removeChild(span);
            }
        });

        $( "#senddata" ).click(function() {

            let validate_name = (!$("#fname").val())? false:true;
            let validate_surname = (!$("#lname").val())? false:true;
            let validate_phone = validatePhonenumber( $("#phone").val() );
            let validate_email = validateEmail($("#email").val());
            let validate_zipcode = validateZipcode($("#zipcode").val());

            function validatePhonenumber( inputtxt ) {

                if( inputtxt.substring(0, 1) !== '0' )
                    inputtxt = "0" + inputtxt;

                if( /^\d{10}$/.test(inputtxt) ) {
                    return true;
                }
                return false;
            }

            function validateEmail(mail) {
                if ( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail) ) {
                    return true;
                }
                return false;
            }

            function validateZipcode(zipcode) {
                if( /(^\d{5}$)|(^\d{5}-\d{4}$)/.test( zipcode ) ) {
                    return true;
                }
                return false;
            }

            setTimeout(() => {
                if( validate_name && validate_surname && validate_phone && validate_email && validate_zipcode ) {
                    register()
                } else {
                    alert("กรุณาตรวจสอบข้อมูลและใส่ข้อมูลให้ครบถ้วนด้วยค่ะ");
                }
            }, 500);

            function register() {

                let firstname = $( "input[name=firstname]" ).val();
                let lastname = $( "input[name=lastname]" ).val();
                let email = $( "input[name=email]" ).val();
                let zipcode = $( "input[name=zipcode]" ).val();

                let phone = "";
                let phone_temp = $( "input[name=phone]" ).val();
                if( phone_temp.substring(0, 1) === '0' )
                    phone = "+66" + phone_temp.substring(1, phone_temp.length);
                else
                    phone = "+66" + phone_temp;

                let raw_data = { firstname: firstname, lastname: lastname, cellphone: phone, email: email, zipcode: zipcode };
                $.ajax({
                    url: 'https://registrationapi.mgcars.com/v1/ap32',
                    type: 'post',
                    dataType: 'json',
                    contentType: 'application/json',
                    headers: {
                        env: "prod"
                    },
                    success: function (data) {
                        if(data.header["res_code"] === 200){
                            location.href = "/thankyou"
                        }else{
                            alert(data.header["res_desc"])
                        }
                    },
                    data: JSON.stringify(raw_data)
                });
            }
        });
    }
}