import { render, staticRenderFns } from "./register.component.html?vue&type=template&id=59fe5c99&scoped=true&"
import script from "./register.component.js?vue&type=script&lang=js&"
export * from "./register.component.js?vue&type=script&lang=js&"
import style0 from "./register.component.css?vue&type=style&index=0&id=59fe5c99&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59fe5c99",
  null
  
)

export default component.exports